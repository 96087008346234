import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: "12px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  category: {
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
}));

const BlogPostCard = ({ post }) => {
  const classes = useStyles();

  return (
    <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none' }}>

    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={post.imageUrl}
        title={post.title}
      />
      <CardContent>
        <Typography variant="subtitle2" className={classes.category}>
          {post.category}
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          {post.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {post.content.substring(0, post.content.indexOf('.', post.content.indexOf('.')))}{'...'}
        </Typography>
      </CardContent>
    </Card>
    </Link>

  );
};

export default BlogPostCard;
