import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { createBlogPost } from "../../utils/firebase.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    margin: "0 auto",
    padding: theme.spacing(3),
    marginTop: theme.spacing(12),

  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const NewBlogForm = ({ onSubmit }) => {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [author, setAuthor] = useState("");

  const [tempAuth, setTempAuth] = useState("");

  const navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newPost = {
        author: author,
        avatarUrl: avatarUrl,
      title: title,
      content: content,
      category: category,
      tags: tags,
      imageUrl: imageUrl,
    };
    

    await createBlogPost(newPost)
    navigation('/blog');


  };
  if (tempAuth !== "123456654321") return <div><form className={classes.root}>
  <TextField
    className={classes.input}
    label=""
    variant="outlined"
    value={tempAuth}
    onChange={(e) => setTempAuth(e.target.value)}
  />
    <TextField
    className={classes.input}
    label=""
    variant="outlined"
    value={""}
    onChange={(e) => e.target.value}
  />
<Button variant="contained" color="primary" type="submit">
        Submit
      </Button></form></div>

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        className={classes.input}
        label="Title"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        className={classes.input}
        label="Content"
        variant="outlined"
        multiline
        rows={8}
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <TextField
        className={classes.input}
        label="Category"
        variant="outlined"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <TextField
        className={classes.input}
        label="Tags"
        variant="outlined"
        value={tags}
        onChange={(e) => setTags(e.target.value)}
      />
      <TextField
        className={classes.input}
        label="Image URL"
        variant="outlined"
        value={imageUrl}
        onChange={(e) => setImageUrl(e.target.value)}
      />
            <TextField
        className={classes.input}
        label="Author"
        variant="outlined"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
      />
      <TextField
        className={classes.input}
        label="Avatar URL"
        variant="outlined"
        value={avatarUrl}
        onChange={(e) => setAvatarUrl(e.target.value)}
      />

      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default NewBlogForm;
