import ButtonCustom, {BUTTON_TYPE_CLASSES} from "../button-custom/button-custom.component"
import DashboardImg from '../../assets/images/dashboard-top-clusters.svg';

import {HeaderContainer, HeaderBackground, HeaderContent, HeaderContentText, HeaderTitle, HeaderTitleText, HeaderTitleTextGradient, HeaderSubTitle, HeaderCreditCardText, HeaderDashboardImg, PlayerWrapper, Player} from "./header-w-video.styles"

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/youtube'

const HeaderWVideo = () => {
    const [imageURL, setImageURL] = useState('');
    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 769) {
            setImageURL('https://res.cloudinary.com/dqdrb5vyi/image/upload/v1680101537/topclusters-laptop-640x360-transparent_jeq1id.png');
          } else if (window.innerWidth < 1024) {
            setImageURL('https://res.cloudinary.com/dqdrb5vyi/image/upload/v1680101489/topclusters-laptop-1024x576-transparent_fkryur.png');
          } else {
            setImageURL('https://res.cloudinary.com/dqdrb5vyi/image/upload/v1680103126/topclusters-laptop-1280x720-transparent_adjmfm.png');
          }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <HeaderContainer id="home">
        <HeaderBackground>
        </HeaderBackground>
            <HeaderContent>
                <HeaderContentText>
                    <HeaderTitle>
                        <HeaderTitleText>
                        Boost your
                        <span> </span>
                        <HeaderTitleTextGradient>
                        google ranking
                        </HeaderTitleTextGradient>
                        <span> </span>
                        with
                        <span> </span>
                        <HeaderTitleTextGradient>
                        keyword clustering
                        </HeaderTitleTextGradient>
                        </HeaderTitleText>
                        <HeaderSubTitle>
                        We group keywords with similar search intent into topical clusters to help you improve your rank, increase your visibility with more traffic, and save time.
                        </HeaderSubTitle>
                        <ButtonCustom link="https://app.topclusters.io/auth/register" buttonType={BUTTON_TYPE_CLASSES.base} style={{  "margin": "0 auto"}}>
                            Get 100 Free Credits
                        </ButtonCustom>
                        <HeaderCreditCardText>
                        No credit card required.
                        </HeaderCreditCardText>
                    </HeaderTitle>
                </HeaderContentText>
                {/* <HeaderDashboardImg src={DashboardImg}/> */}
                <PlayerWrapper>
                <ReactPlayer url='https://www.youtube.com/watch?v=P9ENQGM1CHM' 
          light={<img src={imageURL} alt='Thumbnail' />}
          width='100%' height='100%' controls='true'/>
</PlayerWrapper>

            </HeaderContent>
            </HeaderContainer>
    )
}

export default HeaderWVideo