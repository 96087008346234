import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

export const NavigationContainer = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0px 40px 0px 40px;
  font-family: ${(props) => props.theme.textStyles.h6.fontFamily};
  font-size: ${(props) => props.theme.textStyles.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.h6.fontWeight};

  @media screen and (max-width: 964px) {
    font-size: 0.8rem;

  }

  @media screen and (max-width: 480px) {
    width: 90%;
    padding-top: 2rem;
  }

`;

export const LogoContainer = styled(Link)`
  padding: 12px;
`;

export const NavLinks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

`;

export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.textStyles.h6.fontFamily};
  font-size: ${(props) => props.theme.textStyles.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.h6.fontWeight};
`;

export const Divider = styled(Link)`
  padding: 10px 15px;
  color: ${(props) => props.theme.colors.primary};

`;

export const LogInLink = styled.a`
  padding: 10px 30px 10px 15px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.textStyles.h6.fontFamily};
  font-size: ${(props) => props.theme.textStyles.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.h6.fontWeight};
`;

export const LogOutLink = styled.a`
  padding: 10px 15px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.textStyles.h6.fontFamily};
  font-size: ${(props) => props.theme.textStyles.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.h6.fontWeight};
`;

export const SignUpLink = styled.a`
  padding: 10px 15px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.textStyles.h6.fontFamily};
  font-size: ${(props) => props.theme.textStyles.h6.fontSize};
  font-weight: ${(props) => props.theme.textStyles.h6.fontWeight};
`;