import Header from "../header/header.component"
import HeaderRocket from "../header-rocket/header-rocket.component"
import ValueStack from "../value-stack/value-stack.component"
import ValueStack2 from "../value-stack-2/value-stack-2.component"
import Problem from "../problem/problem.component"
import ValueProp from "../value-prop/value-prop.component"
import Guide from "../guide/guide.component"
import How from "../how/how.component"
import Price from "../price/price.component"
import Success from "../success/success.component"
import Faq from "../faq/faq.component"
import Cta from "../cta/cta.component"
import Footer from "../footer/footer.component"
import {HomepageContainer} from "./homepage.styles"
import HeaderWVideo from "../header-w-video/header-w-video.component"

const Homepage = () => {
    return (
        <HomepageContainer>
            {/* <Header/> */}
            <HeaderWVideo/>
            {/* <HeaderRocket/> */}
            {/* <ValueStack/> */}
            <ValueStack2/>
            <Problem/>
            <ValueProp/>
            <Guide/>
            <How/>
            <Price/>
            <Success/>
            <Faq/>
            <Cta/>
            <Footer/>
        </HomepageContainer>
            
            
    )
}

export default Homepage