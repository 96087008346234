import { SuccessContainer, SuccessContent, SuccessImg, Img, SuccessContentTextColumn, SuccessContentTextRow, SuccessContentTitle, SuccessText, SuccessCreditCardText, ButtonContainer } from "./success.styles"
import SuccessImage from "../../assets/images/success-image.png"
import Button, {BUTTON_TYPE_CLASSES} from "../button-custom/button-custom.component"



const Success = () => {
    return(
        <SuccessContainer>
            <SuccessContent>
            <SuccessImg>
                <Img src={SuccessImage} alt="Office workers celebrating success"/>
            </SuccessImg>
            <SuccessContentTextColumn>
            <SuccessContentTitle>
            It’s time to take your SEO game to the next level         
            </SuccessContentTitle>
            <SuccessText>
            At Top Clusters, we understand what it's like to spend hours on keyword research, creating content that doesn't get the rank and visibility it deserves, while trying to keep up with constantly changing search engine algorithms. That's why we developed a simple but highly efficient keyword clustering tool. With our tool, you can streamline your SEO content strategy, be more productive, and become an SEO superhero.</SuccessText>
            <ButtonContainer>
            <Button link="https://app.topclusters.io/auth/register" buttonType={BUTTON_TYPE_CLASSES.base} style={{  "margin": "0 auto", "text-align": "start"
}}>
                            Get 100 Free Credits
                        </Button>
                        <SuccessCreditCardText>
                        No credit card required.
                        </SuccessCreditCardText>
            </ButtonContainer>                        
        </SuccessContentTextColumn>
            </SuccessContent>
        </SuccessContainer>
    )
}

export default Success