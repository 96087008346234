import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  category: {
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
  },
}));

const PostHeader = ({ post }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="subtitle2" className={classes.category}>
          {post.category}
        </Typography>
        </div>
        <div>

        <Typography variant="subtitle1" color="textSecondary">
          {post.createdAt
            ? new Date(post.createdAt)
                .toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })
                .replace(/(\d+)(?:st|nd|rd|th)/, "$1")
            : ""}
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
  <Avatar className={classes.avatar} src={post.avatarUrl}/>
  <Typography variant="subtitle1" color="textSecondary">
    {post.author}
  </Typography>
</div>
    </div>
  );
};

export default PostHeader;
