import { FooterContainer, LogoContainer, CopyrightText, PrivacyPolicyLink, FooterContent } from './footer.styles';
import { Fragment } from 'react';
import {ReactComponent as Logo} from '../../assets/logos/top-clusters-logo-text-lower-v02-transparent-white.svg'



const Footer = () => {
    return(
        <Fragment>
            <FooterContainer>
                <FooterContent>
                <LogoContainer to='/'>
            <Logo height={30} width={225}/>
            </LogoContainer>
                <CopyrightText>
                Copyright 2023 Top Clusters. All rights reserved.
                </CopyrightText>
                <PrivacyPolicyLink to='/privacy-policy'>
                    Privacy Policy
                </PrivacyPolicyLink>
                </FooterContent>
            </FooterContainer>
        </Fragment>    
    )
}

export default Footer