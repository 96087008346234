import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getBlogPostById } from "../../utils/firebase.utils";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, Grid, Avatar, Button } from "@material-ui/core";
import Footer from "../footer/footer.component";
import Cta from "../cta/cta.component";
import PostHeader from "../post-header/post-header.component";
import ReactMarkdown from 'react-markdown'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MuiMarkdown from 'mui-markdown';
import { getAllBlogPosts } from "../../utils/firebase.utils";


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(24),
    marginBottom: theme.spacing(12),
    marginLeft: theme.spacing(24),
    [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2),
      },
  },
  backIcon: {
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(12),
    [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
    },
    color: "#673ab7"
  },
  image: {
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(2),
    borderRadius: "12px"
  },
  cta: {
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),    },
    borderRadius: "12px",
    boxShadow: "5px 5px 30px rgba(0, 0, 0, 0.2)",
  },
}));

const BlogPostPage = () => {
  const classes = useStyles();
  const [post, setPost] = useState(null);
  const { postId } = useParams();

  useEffect(() => {
    // const storedPosts = localStorage.getItem('blogPosts');
    // if (storedPosts) {
    //   const posts = JSON.parse(storedPosts);
    //   const foundPost = posts.find((post) => post.id === postId);
    //   setPost(foundPost);
    //   console.log(foundPost.id)
    const fetchPosts = async () => {
      const posts = await getAllBlogPosts();
      console.log(posts)
      const foundPost = posts.find((post) => post.id === postId);
      setPost(foundPost);
      // localStorage.setItem('blogPosts', JSON.stringify(allPosts));
    };
    fetchPosts();
  }, []);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <>
  <Link to="/blog">
    <ArrowBackIcon className={classes.backIcon}/>
  </Link>
    <div className={classes.root}>

<PostHeader post={post}/>

      <Typography gutterBottom variant="h3" component="h1">
        {post.title}
      </Typography>
      <img className={classes.image} src={post.imageUrl} alt={post.title} />
      <Typography component="div" style={{ lineHeight: 1.6 }}>
  <ReactMarkdown>{post.content}</ReactMarkdown>
  {/* <MuiMarkdown>{post.content}</MuiMarkdown> */}
</Typography>





    </div>
    <div className={classes.cta}>
        <Cta />
      </div>
          <Footer />
          </>
  );
};

export default BlogPostPage;
