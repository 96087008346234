import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FeaturedBlogPost from "../../components/featured-blog-post/featured-blog-post.component";
import BlogPostCard from "../../components/blog-post-card/blog-post-card.component";
import { getAllBlogPosts } from "../../utils/firebase.utils";
import { Typography } from "@material-ui/core";
import Footer from "../../components/footer/footer.component";
import Cta from "../../components/cta/cta.component";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(16),
    [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(16),
      },
  },
  cta: {
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),    },
    borderRadius: "12px",
    boxShadow: "5px 5px 30px rgba(0, 0, 0, 0.2)",
  },

}));

const Blog = () => {
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [featuredPost, setFeaturedPost] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const allPosts = await getAllBlogPosts();
      setPosts(allPosts);
      setFeaturedPost(allPosts[0])
      localStorage.setItem('blogPosts', JSON.stringify(allPosts));
    };
    fetchPosts();
  }, []);

  return (
    <>
    <div className={classes.root}>

      <FeaturedBlogPost post={featuredPost} />
      <Typography gutterBottom component="h3" variant="h3">All Articles</Typography>
      <Grid container spacing={2}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={post.id}>
            <BlogPostCard post={post} />
          </Grid>
        ))}
      </Grid>

      </div>
      <div className={classes.cta}>
        <Cta />
      </div>
            <Footer/>
            </>
  );
};

export default Blog;
