import styled from 'styled-components';

export const PriceContainer = styled.div`
display: flex;
justify-content: center;
position: relative;
overflow: hidden;
min-height: 100%;
`;

export const PriceBackground = styled.div`
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
background: radial-gradient(circle, rgba(220,133,234,1) 11%, rgba(239,194,255,0.10127801120448177) 28%, rgba(189,247,255,1) 62%, rgba(201,212,239,0.25253851540616246) 70%, rgba(234,202,250,1) 81%);
filter: blur(200px);
z-index: -1;
`;

export const PriceContent = styled.div`
padding-bottom: 5rem;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
`;

export const PriceTitle = styled.h2`
width: 60%;
font-size: ${(props) => props.theme.textStyles.h2.fontSize};
font-weight: ${(props) => props.theme.textStyles.h2.fontWeight};
color: ${(props) => props.theme.colors.primary};
padding: 12px 0 0 0;
@media screen and (max-width: 768px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
    width: 90%;

  }
`;

export const PriceSubtitle = styled.p`
width: 50%;
font-size: ${(props) => props.theme.textStyles.paragraphLarge.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphLarge.fontWeight};
line-height: ${(props) => props.theme.textStyles.paragraphLarge.lineHeight};
color: ${(props) => props.theme.colors.primary};
padding: 0 0 56px 0;
@media screen and (max-width: 768px) {
  width: 80%;
  padding: 0 0 24px 0;
}

`;

export const PriceItemsContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
align-items: center;
`;

export const PriceItem = styled.div`
width: 300px;
height: auto;
background: ${(props) => props.theme.colors.background};
border-radius: 24px;
`;

export const PriceItemContent = styled.div`
padding-bottom: 24px;

`;

export const PriceItemTitle = styled.h4`
font-size: ${(props) => props.theme.textStyles.h4.fontSize};
font-weight: ${(props) => props.theme.textStyles.h4.fontWeight};
line-height: ${(props) => props.theme.textStyles.h4.lineHeight};
background: linear-gradient(53.94deg, #A737FF 15.02%, #6B7CFF 82.83%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin: 12px 0;
`;

export const PriceItemPrice = styled.h3`
font-size: ${(props) => props.theme.textStyles.h3.fontSize};
font-weight: ${(props) => props.theme.textStyles.h3.fontWeight};
line-height: ${(props) => props.theme.textStyles.h3.lineHeight};
color: ${(props) => props.theme.colors.primary};
margin: 12px 0;
`;

export const PriceItemDivider = styled.div`
// width: 100%;
border-top: 3px solid #bbb;
`;

export const PriceDetailsContainer = styled.div`
text-align: start;
padding: 24px 32px;
`;

export const PriceDetails = styled.div`
padding: 12px 0;
`;


export const PriceDetailsText = styled.span`
font-size: ${(props) => props.theme.textStyles.paragraph.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphLargeBold.fontWeight};
line-height: ${(props) => props.theme.textStyles.paragraphLargeBold.lineHeight};
color: ${(props) => props.theme.colors.primary};
vertical-align: middle;
`;

export const PriceIconContainer = styled.div`
display: inline-block;
vertical-align: middle;
margin-top: 6px;
`;

export const PriceCreditCardText = styled.p`
font-size: ${(props) => props.theme.textStyles.paragraphSmall.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphSmall.fontWeight};
color: ${(props) => props.theme.colors.black};

`;