import styled from 'styled-components';

export const CtaContainer = styled.div`
display: flex;
justify-content: center;
position: relative;
overflow: hidden;
height: auto;
`;

export const CtaBackground = styled.div`
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
// background: radial-gradient(circle, rgba(220,133,234,0.1) 11%, rgba(239,194,255,0.10127801120448177) 28%, rgba(189,247,255,0.2) 62%, rgba(201,212,239,0.25253851540616246) 70%, rgba(234,202,250,0.2) 81%);
filter: blur(100px);
z-index: -1;

`;

export const CtaContent = styled.section`
display: flex;
justify-content: space-around;
align-items: center;
margin: 4rem 0;
`;

export const CtaContentText = styled.div`
width: 60%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
font-family: ${(props) => props.theme.textStyles.h1.fontFamily};
color: ${(props) => props.theme.colors.primary};
@media screen and (max-width: 1124px) {
    width: 80%;
  }
@media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CtaTitle = styled.div`
font-size: ${(props) => props.theme.textStyles.h1.fontSize};
font-weight: ${(props) => props.theme.textStyles.h1.fontWeight};
margin-bottom: 12px;

@media screen and (max-width: 768px) {
    font-size: 3rem;

  }

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;

  }
`;

export const CtaTitleText = styled.span`
display: block;

`;

export const CtaTitleTextGradient = styled.span`
background: linear-gradient(53.94deg, #A737FF 15.02%, #6B7CFF 82.83%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

export const CtaSubTitle = styled.h2`
font-size: ${(props) => props.theme.textStyles.paragraphLarge.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphLarge.fontWeight};
line-height: ${(props) => props.theme.textStyles.paragraphLarge.lineHeight};
padding: 1rem 0;
`;

export const CtaCreditCardText = styled.p`
font-size: ${(props) => props.theme.textStyles.paragraphSmall.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphSmall.fontWeight};
color: ${(props) => props.theme.colors.black};
margin-top: 12px;
`;