import styled from 'styled-components';

export const ValuePropContainer = styled.div`
display: flex;
justify-content: center;
position: relative;
overflow: hidden;
min-height: 100%;
`;

export const ValuePropBackground = styled.div`
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
background: radial-gradient(circle, rgba(215,152,225,0.5) 8%, rgba(115,228,237,0.1) 20%, rgba(189, 247, 255, 0.5) 22%, rgba(201,212,239,0.1) 0%, rgba(202,207,250,0.2) 0%);
filter: blur(800px);
background: radial-gradient(circle, rgba(220,133,234,0.1) 11%, rgba(239,194,255,0.10127801120448177) 28%, rgba(189,247,255,0.2) 62%, rgba(201,212,239,0.25253851540616246) 70%, rgba(234,202,250,0.2) 81%);

z-index: -1;
`;

export const ValuePropContent = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;


export const ValuePropTitle = styled.h2`
width: 100%;
font-size: ${(props) => props.theme.textStyles.h2.fontSize};
font-weight: ${(props) => props.theme.textStyles.h2.fontWeight};
color: ${(props) => props.theme.colors.primary};
padding: 12px 0 38px 0;
text-align: center;
@media screen and (max-width: 768px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

export const ValuePropBenefitsContent = styled.div`
width: 100%;
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 56px;
grid-row-gap: 36px;
padding-bottom: 36px;
justify-content: center;
align-content: center;
@media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

@media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
}
`;

export const ValuePropBenefit1 = styled.div`
grid-area: 1 / 1 / 2 / 2;
text-align: start;


@media screen and (max-width: 768px) {
    grid-area: 1 / 1 / 1 / 2;
}

@media screen and (max-width: 480px) {
    grid-area: 1 / 1 / 1 / 6;
}
`;
export const ValuePropBenefit2 = styled.div`
grid-area: 1 / 2 / 2 / 3;
text-align: start;

@media screen and (max-width: 768px) {
    grid-area: 1 / 2 / 2 / 2;
}
@media screen and (max-width: 480px) {
    grid-area: 2 / 1 / 2 / 6;
}
`;
export const ValuePropBenefit3 = styled.div`
grid-area: 1 / 3 / 2 / 4;
text-align: start;

@media screen and (max-width: 768px) {
    grid-area: 2 / 1 / 2 / 1;
}

@media screen and (max-width: 480px) {
    grid-area: 3 / 1 / 3 / 6;
}

`;
export const ValuePropBenefit4 = styled.div`
grid-area: 2 / 1 / 3 / 2;
text-align: start;

@media screen and (max-width: 768px) {
    grid-area: 2 / 2 / 2 / 2;
}

@media screen and (max-width: 480px) {
    grid-area: 4 / 1 / 4 / 6;
}
`;
export const ValuePropBenefit5 = styled.div`
grid-area: 2 / 2 / 3 / 3;
text-align: start;

@media screen and (max-width: 768px) {
    grid-area: 3 / 1 / 3 / 1;
}

@media screen and (max-width: 480px) {
    grid-area: 5 / 1 / 5 / 6;
}
`;
export const ValuePropBenefit6 = styled.div`
grid-area: 2 / 3 / 3 / 3;
text-align: start;

@media screen and (max-width: 768px) {
    grid-area: 3 / 2 / 3 / 2;
}

@media screen and (max-width: 480px) {
    grid-area: 6 / 1 / 6 / 6;
}
`;

export const ValuePropBenefitTitle = styled.h4`
font-size: ${(props) => props.theme.textStyles.h4.fontSize};
font-weight: ${(props) => props.theme.textStyles.h4.fontWeight};
line-height: ${(props) => props.theme.textStyles.h4.lineHeight};
color: ${(props) => props.theme.colors.primary};
margin: 12px 0;
`;

export const ValuePropBenefitText = styled.p`
font-size: ${(props) => props.theme.textStyles.paragraphLarge.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphLarge.fontWeight};
line-height: ${(props) => props.theme.textStyles.paragraphLarge.lineHeight};
color: ${(props) => props.theme.colors.primary};
`;

export const ValuePropIconContainer = styled.div`
`;

export const ValuePropCreditCardText = styled.p`
font-size: ${(props) => props.theme.textStyles.paragraphSmall.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphSmall.fontWeight};
color: ${(props) => props.theme.colors.black};
`;