
import styled from 'styled-components';


export const ValueStackContainer = styled.div`
position: relative;
width: 100%;
height: auto;
`;

export const ValueStackContent = styled.section`
display: flex;
justify-content: center;
align-items: center;
background-color: ${(props) => props.theme.colors.lightViolet};
width: 100%;
height: auto;
`;

export const ValueStackCardContent = styled.div`
display: flex;
justify-content: space-between;
margin: 2rem 0;
width: 100%;
height: auto;

@media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ValueStackCard = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
width: 30rem;
padding: 1rem;
`;

export const ValueStackIconContainer = styled.div`
padding: 1.5rem;
@media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0rem 0rem 0rem;
  }
`;

export const ValueStackCardTitle = styled.h3`
font-size: ${(props) => props.theme.textStyles.h3.fontSize};
font-weight: ${(props) => props.theme.textStyles.h3.fontWeight};
color: ${(props) => props.theme.colors.primary};
padding-bottom: 2.5rem;

@media (max-width: 768px) {
    font-size: 2rem;

  }
`;