import { HowContainer, HowBackground, HowContent, HowTitle, HowStepsContent, HowStepsTitle, HowStepsText, HowIconContainer, HowStepsContainer1, HowStepsContainer2, HowStepsContainer3, HowStepsContainer4, HowStepsContainer5, HowStepsContainer6 } from "./how.styles"
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as HubIcon } from '../../assets/icons/hub_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as LabIcon } from '../../assets/icons/lab_profile_FILL0_wght400_GRAD0_opsz48.svg';


const How = () => {
    return(
<HowContainer id="how">
<HowBackground>
</HowBackground>
<HowContent>
    <HowTitle>
        How it works
    </HowTitle>
    <HowStepsContent>
        <HowStepsContainer1>
            <HowIconContainer>
                <UploadIcon/>
            </HowIconContainer>
        </HowStepsContainer1>
        <HowStepsContainer2>
            <HowStepsTitle>
                1. Upload keyword list
            </HowStepsTitle>
            <HowStepsText>
            Upload a CSV file of keywords from your favorite keyword research tool. We support Google Keyword Planner, Ahrefs and Semrush. Alternatively, you can upload your own custom keyword file. 
            </HowStepsText>
        </HowStepsContainer2>
        <HowStepsContainer3>
        <HowIconContainer>
                <HubIcon/>
            </HowIconContainer>
        </HowStepsContainer3>
        <HowStepsContainer4>
        <HowStepsTitle>
                2. Cluster keywords
            </HowStepsTitle>
            <HowStepsText>
            Choose between two of our state-of-the-art clustering algorithms: Natural Language Processing (NLP) or Google SERP.            </HowStepsText>
        </HowStepsContainer4>
        <HowStepsContainer5>
        <HowIconContainer>
                <LabIcon/>
            </HowIconContainer>
        </HowStepsContainer5>
        <HowStepsContainer6>
        <HowStepsTitle>
                3. Receive report
            </HowStepsTitle>
            <HowStepsText>
            Depending on the size of your keyword list, you will receive an email notification within a few minutes, informing you that your keyword clustering report is ready. The report will clearly indicate the main keyword each page should target and all the other keywords that can be targeted by that page.            </HowStepsText>
        </HowStepsContainer6>
    </HowStepsContent>
</HowContent>
</HowContainer>
    )
}


export default How