import { Routes, Route } from "react-router-dom";
import Home from "../routes/home/home.component";
import Navigation from "../routes/navigation/navigation.component";
// import SignIn from "../routes/sign-in/sign-in.component";
import Authentication from "../routes/authentication/authentication.component";
import PrivacyPolicy from "../routes/privacy-policy/privacy-policy.component";
import Blog from "../routes/blog/blog.component";
import BlogPostPage from "../components/blog-post-page/blog-post-page.component";
import NewBlogForm from "../routes/new-blog-form/new-blog-form.component";

const HomeRouter = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="auth" element={<Authentication />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="blog" element={<Blog />} />
          <Route path="/blog/:postId" element={<BlogPostPage/>} />
          <Route path="/blog/create-blog" element={<NewBlogForm/>} />
        </Route>
      </Routes>
    );
  };

  // const AppRouter = () => {
  //   return (
  //     <Routes>
  //       <Route path="/" element={<Authentication />}>
  //         {/* <Route index element={<Home />} /> */}
  //         {/* <Route path="sign-in" element={<SignIn />} /> */}
  //       </Route>
  //     </Routes>
  //   );
  // };
  
  export {HomeRouter};