import styled from 'styled-components';

export const HowContainer = styled.div`
display: flex;
justify-content: center;
position: relative;
overflow: hidden;
height: auto;
`;

export const HowBackground = styled.div`
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
background: linear-gradient(355deg, rgba(255,255,255,0.5634628851540616) 0%, rgba(230,242,252,0.1) 15%, rgba(189,239,255,0.6138830532212884) 69%, rgba(255,255,255,1) 87%);
z-index: -1;

`;

export const HowContent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
`;

export const HowTitle = styled.h2`
width: 60%;
font-size: ${(props) => props.theme.textStyles.h2.fontSize};
font-weight: ${(props) => props.theme.textStyles.h2.fontWeight};
color: ${(props) => props.theme.colors.primary};
padding: 12px 0 38px 0;
margin-top: 4rem;
@media screen and (max-width: 768px) {
    font-size: 3rem;
    padding: 6px 0 24px 0;

  }
  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
    width: 80%;
    padding: 6px 0 18px 0;

  }
`;

export const HowStepsContent = styled.div`
width: 60%;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 24px;
grid-row-gap: 0px;
margin-bottom: 4rem;
@media screen and (max-width: 768px) {
    width: 80%;
}
`;

export const HowStepsContainer1 = styled.div`
grid-area: 1 / 1 / 3 / 2;
`;
export const HowStepsContainer2 = styled.div`
grid-area: 1 / 2 / 2 / 12;
text-align: start;
`;
export const HowStepsContainer3 = styled.div`
grid-area: 2 / 1 / 3 / 2;

`;
export const HowStepsContainer4 = styled.div`
grid-area: 2 / 2 / 3 / 12;
text-align: start;

`;
export const HowStepsContainer5 = styled.div`
grid-area: 3 / 1 / 4 / 2;

`;
export const HowStepsContainer6 = styled.div`
grid-area: 3 / 2 / 4 / 12;
text-align: start;
`;

export const HowStepsTitle = styled.h3`
font-size: ${(props) => props.theme.textStyles.h3.fontSize};
font-weight: ${(props) => props.theme.textStyles.h3.fontWeight};
line-height: ${(props) => props.theme.textStyles.h3.lineHeight};
color: ${(props) => props.theme.colors.primary};
margin: 12px 0;
@media screen and (max-width: 768px) {
    font-size: 2rem;

}

@media screen and (max-width: 480px) {
    font-size: 1.8rem;

}
`;

export const HowStepsText = styled.p`
width: 100%;
font-size: ${(props) => props.theme.textStyles.paragraphLarge.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphLarge.fontWeight};
line-height: ${(props) => props.theme.textStyles.paragraphLarge.lineHeight};
color: ${(props) => props.theme.colors.primary};
`;

export const HowIconContainer = styled.div`
`;