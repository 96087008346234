import styled from 'styled-components';
import DashboardImg from '../../assets/images/dashboard-top-clusters.svg';


export const HeaderContainer = styled.div`
position: relative;
overflow: hidden;
height: 150vh;

@media screen and (max-width: 1024px) {
    height: 150vh;
  }

@media screen and (max-width: 768px) {
    height: auto;
  }

`;

export const HeaderBackground = styled.div`
position: relative;
top: 280px;
left: 320px;
width: 624px;
height: 768px;
background: radial-gradient(circle at -49.5% -250%, rgba(215, 152, 225, 1) 17.55%, rgba(255, 155, 245, 1) 27.56%, rgba(174, 211, 255, 1) 49.89%, rgba(201, 212, 239, 1) 56.53%, rgba(202, 207, 250, 1) 65.69%);
filter: blur(100px);

@media screen and (max-width: 1024px) {
    top: 280px;
    left: 120px;
    width: 100%;
    height: 200px;
  }

@media screen and (max-width: 768px) {
    top: 180px;
    left: 100px;
    width: 100%;
    height: 50px;
  }
`;

export const HeaderContent = styled.section`
height: 100%;
width: 100%;
position: absolute;
top: 0;
right: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
color: #000000;

@media screen and (max-width: 768px) {
    position: relative;
  }
`;

export const HeaderContentText = styled.div`
width: 50%;
padding-top: 5rem;
margin-top: 8rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
font-family: ${(props) => props.theme.textStyles.h1.fontFamily};
color: ${(props) => props.theme.colors.primary};

@media screen and (max-width: 768px) {
    width: 70%;
    padding-top: 2rem;
    margin-top: 2.5rem;

  }

  @media screen and (max-width: 480px) {
    width: 90%;
    padding-top: 2rem;
    margin-top: 2.5rem;

  }
`;

export const HeaderTitle = styled.div`
font-size: ${(props) => props.theme.textStyles.h1.fontSize};
font-weight: ${(props) => props.theme.textStyles.h1.fontWeight};

@media screen and (max-width: 768px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

export const HeaderTitleText = styled.span`
display: block;
`;

export const HeaderTitleTextGradient = styled.span`
background: linear-gradient(53.94deg, #A737FF 15.02%, #6B7CFF 82.83%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

export const HeaderSubTitle = styled.h2`
font-size: ${(props) => props.theme.textStyles.paragraphLarge.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphLarge.fontWeight};
padding: 2rem 0rem;

@media screen and (max-width: 768px) {
    padding: 1rem 0rem 2rem 0rem;
  }
`;

export const HeaderCreditCardText = styled.p`
font-size: ${(props) => props.theme.textStyles.paragraphSmall.fontSize};
font-weight: ${(props) => props.theme.textStyles.paragraphSmall.fontWeight};
color: ${(props) => props.theme.colors.black};
padding: 0rem 0rem 1rem 0rem;

`;

export const HeaderDashboardImg = styled.img`
box-sizing: border-box;
width: 1024px;
height: 666.67px;

@media screen and (max-width: 1024px) {
    width: 768px;
    height: 500px;

  }

@media screen and (max-width: 768px) {
    width: 480px;
    height: 312.5px;
  }

  @media screen and (max-width: 480px) {
    width: 450px;
    height: 220px;
  }
`;

export const PlayerWrapper = styled.div`
position: relative;
width: 997px;
height: 576px;
margin-top: 3.5rem;

@media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 0;
  }
  @media screen and (max-width: 768px) {
    width: 640px;
    height: 360px;
  }
  @media screen and (max-width: 480px) {
    width: 360px;
    height: 240px;
    margin-top: 2.5rem;

  }
`;

export const Player = styled.div`
// position: absolute;
// top: 0;
// left: 0;
// @media screen and (max-width: 1024px) {
//     top: 280px;
//     left: 120px;
//     width: 100%;
//     height: 200px;
//   }

// @media screen and (max-width: 768px) {
//     top: 180px;
//     left: 100px;
//     width: 100%;
//     height: 50px;
//   }
`;