import { BrowserRouter } from "react-router-dom";
import { HomeRouter } from "./routers/routers";
import { UserProvider } from "./context/user.context";
import { ThemeProviderFunc } from "./context/theme.context";

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <ThemeProviderFunc>
          {/* <CurrentApp /> */}
          <HomeRouter />
        </ThemeProviderFunc>
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;
