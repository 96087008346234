import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { colorPalette, theme } from '../../utils/theme.utils';
import { ReactComponent as TrendingUpIcon } from '../../assets/icons/trending_up_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as VisibilityIcon } from '../../assets/icons/visibility_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/schedule_FILL0_wght400_GRAD0_opsz48.svg';
import { fontSize } from '@mui/system';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  card: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    // backgroundColor: colorPalette.lightViolet,
},
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: colorPalette.purple,
    fontSize: theme.textStyles.h3.fontSize,
    fontWeight: theme.textStyles.h3.fontWeight
  },
}));

const cardsData = [
  {
    icon: <TrendingUpIcon/>,
    text: 'Improve your rank',
  },
  {
    icon: <VisibilityIcon/>,
    text: 'Increase your visibility',
  },
  {
    icon: <ScheduleIcon/>,
    text: 'Save time',
  },
];

const ValueStack2 = () => {
  const classes = useStyles();
  const theme = useTheme();


  return (
    <Grid container spacing={0}>
      {cardsData.map((card, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                {card.icon}
              <Typography variant="h6" component="p" align='center'>
                {card.text}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ValueStack2;
