import {
  BaseButton,
  GoogleSignInButton,
  InvertedButton,
} from './button-custom.styles';

export const BUTTON_TYPE_CLASSES = {
  base: 'base',
  google: 'google-sign-in',
  inverted: 'inverted',
};

const getButton = (buttonType = BUTTON_TYPE_CLASSES.base) =>
  ({
    [BUTTON_TYPE_CLASSES.base]: BaseButton,
    [BUTTON_TYPE_CLASSES.google]: GoogleSignInButton,
    [BUTTON_TYPE_CLASSES.inverted]: InvertedButton,
  }[buttonType]);

const ButtonCustom = ({ children, buttonType, link, ...otherProps }) => {
  const CustomButton = getButton(buttonType);
  if (link) {
    return (
      <a href={link} style={{ display: 'flex', justifyContent: 'center' }}>
        <CustomButton>{children}</CustomButton>
      </a>
    );
  } else {
    return (
      <CustomButton {...otherProps}>
        {children}
      </CustomButton>
    );
  }
};

export default ButtonCustom;