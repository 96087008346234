import { Fragment, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import React from "react";
// import { HashLink } from 'react-router-hash-link';

import { colorPalette } from '../../utils/theme.utils';

import { AppBar,
Toolbar,
CssBaseline,
Typography,
useScrollTrigger,
Slide,
Menu,
Box,
MenuItem,
Container,
List,
ListItem,
Drawer,
IconButton,
ListItemText,
useTheme,
useMediaQuery
} from '@material-ui/core';

import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';

import { UserContext } from '../../context/user.context';

import { signOutUser } from '../../utils/firebase.utils';
import ButtonCustom, { BUTTON_TYPE_CLASSES } from '../../components/button-custom/button-custom.component'

import {ReactComponent as Logo} from '../../assets/logos/top-clusters-logo-text-lower-v02-transparent.svg'

import {
  NavigationContainer,
  NavLinks,
  NavLink,
  LogoContainer,
  LogInLink,
  LogOutLink,
  Divider,
  SignUpLink
} from './navigation.styles';


const Navigation = (props) => {
  const { currentUser } = useContext(UserContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // isMobile = false;


  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

  // TODO: Add anchor links

    return (
      <Fragment>
      <HideOnScroll {...props}>
      <AppBar color="white">
          <Toolbar >
          <LogoContainer to='/'>
            <Logo className='logo'  height={42} width={150}/>
            </LogoContainer>
            <NavLinks>
            {isMobile ? (<Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
         <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <NavLink to='/#home'>
              Home
              </NavLink>            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <NavLink to='/#product'>
              Product
              </NavLink>            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <NavLink to='/#how'>
              How It Works
              </NavLink>            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <NavLink to='/#pricing'>
              Pricing
              </NavLink>            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <NavLink to='/#faq'>
              FAQ
              </NavLink>           </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <NavLink to='/blog'>
              Blog
              </NavLink>           </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            
              <LogInLink href="https://app.topclusters.io/" >Log In</LogInLink>

                      </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <SignUpLink href="https://app.topclusters.io/auth/register/">
              Sign Up
              </SignUpLink>           
              </ListItemText>
          </ListItem>
        </List>
      </Drawer>):(
                    <NavLinks>

            <NavLink to='/#home'>
              Home
              </NavLink>
              <NavLink to='/#product'>
              Product
              </NavLink>
              <NavLink to='/#how'>
              How It Works
              </NavLink>
              <NavLink to='/#pricing'>
              Pricing
              </NavLink>
              <NavLink to='/#faq'>
              FAQ
              </NavLink>
              <NavLink to='/blog'>
              Blog
              </NavLink>
              <Divider>
              |
              </Divider>
            {currentUser ? (
              <LogOutLink onClick={signOutUser}>
              SIGN OUT
            </LogOutLink>
            ) : (
              <LogInLink href="https://app.topclusters.io/" >Log In</LogInLink>

            )}
            <ButtonCustom buttonType={BUTTON_TYPE_CLASSES.base} link="https://app.topclusters.io/auth/register">Sign Up</ButtonCustom>
            </NavLinks> )}
            </NavLinks>
            {isMobile && (      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon sx={{color: colorPalette.purple}}/>
      </IconButton>) }

          </Toolbar>
        </AppBar> 
        </HideOnScroll>

        <Outlet />
      </Fragment>
    );
  };

  export default Navigation;
