import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(8),
    borderRadius: "12px",
    boxShadow: "5px 5px 30px rgba(0, 0, 0, 0.2)", // wider and more blurry boxShadow

  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
}));

const FeaturedBlogPost = ({ post }) => {

  const classes = useStyles();
//   console.log(post)
  if (!post) return null;
  return (
    <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none' }}>

    <Card className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardMedia
            className={classes.media}
            image={post.imageUrl}
            title={post.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary">
              Featured Article
            </Typography>
            <Typography variant="h5" component="h5">
              {post.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
    {post.content ? post.content.split('\n').slice(0, 1).join('\n') : ''}{'...'}
</Typography>

          </CardContent>
        </Grid>
      </Grid>
    </Card>
    </Link>

  );
};

export default FeaturedBlogPost;
